.dropdown {
  /*
    We need to position the dropdown relatively so the dropdown area can be
    positioned absolutely.
  */
  position: relative;
  height: max-content;

  /* Disable user selection. */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* #region Dropdown Toggler */
.dropdown .dropdownToggler {
  /*
    We position the dropdown toggler above the dropdown area to cover up the
    overlapping shadowed areas.
  */
  position: relative;

  /* We give some space around the dropdown toggler to avoid tight packing. */
  /* padding: 0.5rem; */

  /* The corners of the dropdown should be slightly rounded to pop out. */
  border-radius: var(--border-radius);
  cursor: pointer;
  overflow: hidden;
}

.dropdown.toggled .dropdownToggler {
  /* The toggler should match the style of the area when toggled. */
  background-color: var(--color-fill-body);
  box-shadow: var(--shadow);
}

.dropdown.toggled .dropdownToggler::before {
  content: "";

  /* This pseudo-element should stretch over the original element. */
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;

  /* Hide the dropdown toggler shadow beneath the dropdown area. */
  z-index: 25;
  background-color: inherit;
}

.dropdown.top-left .dropdownToggler,
.dropdown.top-right .dropdownToggler {
  /* Top-facing togglers should be flat at the top. */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown.bottom-left .dropdownToggler,
.dropdown.bottom-right .dropdownToggler {
  /* Bottom-facing togglers should be flat at the bottom. */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* #endregion */

/* #region Dropdown Area */
.dropdown .dropdownArea {
  /*
    We position the dropdown area below the dropdown toggler and based on the
    specified side.
  */
  min-width: 100%;
  display: none;
  position: absolute;
  z-index: 20;

  /*
    The dropdown area should match dropdown toggler width and items should be
    kept to single lines instead of wrapping.
  */
  white-space: nowrap;

  /* Give the dropdown a distinct look from surrounding elements. */
  background: var(--color-fill-body);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

.dropdown.toggled .dropdownArea {
  display: block;
}

.dropdown.top-left .dropdownArea {
  /* Position above and facing left. */
  bottom: 100%;
  right: 0%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown.top-right .dropdownArea {
  /* Position above and facing right. */
  bottom: 100%;
  left: 0%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown.bottom-left .dropdownArea {
  /* Position below and facing left. */
  top: 100%;
  right: 0%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown.bottom-right .dropdownArea {
  /* Position below and facing right. */
  top: 100%;
  left: 0%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* #endregion */

/* #region Dropdown Item */
.dropdownItem {
  /* Dropdown items should have some padding around them to give spacing. */
  display: block;
  padding: 0.25rem 0.5rem;
}

.dropdownItemLink{
  text-decoration: none;
  color: unset;
}

.dropdown.hover .dropdownItem:hover {
  background-color: var(--color-primary-hover);
  cursor: pointer;
}


/* #endregion */