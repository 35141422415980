.text > * {
  flex-shrink: 0;
}

/* Padding */
.text.padded-center {
  margin: 0.5rem 0rem;
}
.text.padded-top {
  margin: 1rem 0rem 0rem 0rem;
}
.text.padded-bottom {
  margin: 0rem 0rem 1rem 0rem;
}

/* Text sizes */
.text.size-reset {
  font-size: 1rem;
}
.text.size-small {
  font-size: var(--font-small);
}
.text.size-normal {
  font-size: var(--font-normal);
}
.text.size-medium {
  font-size: var(--font-medium);
}
.text.size-large {
  font-size: var(--font-large);
}
.text.size-title {
  font-size: var(--font-title);
}

/* Text colors */
.text.color-normal {
  color: var(--color-stroke-normal);
}
.text.color-notify {
  color: var(--color-notify);
}
.text.color-info {
  color: var(--color-info);
}
.text.color-warning {
  color: var(--color-warning);
}
.text.color-error {
  color: var(--color-error);
}
.text.color-muted {
  color: var(--color-muted);
}

/* Text alignment */
.text.halign-left {
  text-align: left;
}
.text.halign-right {
  text-align: right;
}
.text.halign-center {
  text-align: center;
}
.text.valign-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.text.valign-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.text.valign-middle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
