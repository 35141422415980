/* Defines how to apply theming and globals to basic UI elements. */

/* The body should use the basic colors for foreground and background. */
body {
  color: var(--color-stroke-normal);
  background-color: var(--color-fill-body);
  font-family: "Source Sans Pro", sans-serif !important;
}
input {
  font-family: "Source Sans Pro", sans-serif !important;
}
hr {
  border: none;
  border-top: 1px solid var(--color-stroke-muted);
  opacity: 0.5;
}

/* Selections should be based on the primary color. */
::selection {
  background-color: var(--color-primary-select);
}
::-moz-selection {
  background-color: var(--color-primary-select);
}

/* Scrollbars should be consistent and prettier. */
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
*::-webkit-scrollbar-track {
  background-color: var(--color-fill-element-lowlight);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-disabled);
  border-radius: 2px;
}
html {
  scrollbar-width: 2px;
  scrollbar-color: var(--color-fill-element-lowlight) var(--color-primary);
}

/* Vertically normalizes text environments. */
.normal-text {
  display: inline-flex;
  align-items: center;
}
