.row {
  /* Displays contents of the row across the width of the screen in a flexbox. */
  display: flex;
  flex-direction: row;
  width: 100%;
}
.column {
  /* Sets the column up to displayed as evenly split in the row. */
  flex: 1;

  /* Displays context of the column across the height of the screen in a flexbox. */
  display: flex;
  flex-direction: column;
  height: 100%;
}
