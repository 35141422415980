/* #region Layout */
.layout-container {
  /*
    We make the layout container flex in the column direction so that
    the header and footer can be properly placed.
  */
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.layout-content {
  /* The content of a layout should grow to fill remaining space. */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
/* #endregion */

/* #region Header/Footer */
.header,
.footer {
  /* The header and footer should be displayed as an elevated element with a shadow. */
  background-color: var(--color-fill-element);
  box-shadow: var(--shadow);
  padding: 1rem;
  z-index: 1;

  /* Allows the header to be scrolled when displayed on a small screen. */
  /* overflow-x: auto; */
  /* overflow-y: visible; */
}
.header {
  /* Center icons and links in the header vertically and allow for stretching. */
  display: flex;
  align-items: center;
}
.footer {
  /* Footer text should be smaller and lighter since it is non-important. */
  font-size: var(--font-small);
  color: var(--color-stroke-muted);
}
/* #endregion */

/* #region Navigation */
.nav-link {
  /* Links should have a special color and look to them. */
  margin: 0rem 0.5rem;
  color: var(--color-stroke-lowlight);
  text-decoration: none;
}
.nav-link.lower {
  /* Specific types of navigation links should be styled lowercase. */
  text-transform: lowercase;
}
.nav-link:hover {
  /* Links change color when hovered over. */
  color: var(--color-stroke-normal);
}

.nav-link-list {
  /* The link list should have some spacing and grow to fill space. */
  flex-grow: 1;
  margin: 0rem 1rem;
}
.nav-link-list li {
  /* Links should be displayed in an inline list. */
  display: inline;
}
/* #endregion */

/* #region Wrapper */
.wrapper {
  padding: 2rem 4rem;
  flex-grow: 1;
  display: flex;
}
/* #endregion */

@media screen and (max-width: 768px) {
    /* #region Wrapper */
  .wrapper {
    padding: 2rem 0.125rem;
    flex-grow: 1;
    display: flex;
  }
  /* #endregion */
}
