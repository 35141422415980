/* This is the default theme for colors that do not change. */
body {
  --color-notify: #6ab10d;
  --color-info: #5aabf7;
  --color-warning: #ffae00;
  --color-error: #ce221c;
  --color-muted: #999999;

  --color-stroke-dark: #222222;
  --color-stroke-light: #dddddd;

  /* TODO: Figure out how to standardize the primary and secondary colors for stroke and fill. */
  --color-fill-normal: #ffffff;
  --color-fill-primary: #53b853;
  --color-fill-secondary: #a1a7a1;

  /* --color-stroke-normal: #000000; */
  --color-stroke-primary: #141414;
  --color-stroke-secondary: #141414;
  /* --color-stroke-hairline: #22222233; */

  /* TODO: Standardize this. */
  --color-fill-elevated: var(--color-fill-element);

  /* Default to ligt colors. TODO: if light/dark mode is enabled, 
   * this should be removed.*/
  --color-stroke-normal: #222222;
  --color-stroke-muted: #808080;
  /* --color-stroke-hairline: #f6f6f6; */
  --color-stroke-hairline: #22222233;
  --color-stroke-lowlight: #404040;
  --color-primary: #53b853;
  --color-primary-stroke: #141414;
  --color-primary-hover: #f0f0f0;
  --color-primary-select: #53b85360;
  --color-primary-disabled: #a0d6a0;
  --color-secondary: #a1a7a1;
  --color-secondary-stroke: #141414;
  --color-fill-body: #fafafa;
  --color-fill-element: #ffffff;
  --color-fill-element-lowlight: #f4f4f4;
  --color-fill-overlay: #ffffff80;
}

.theme-light {
  /* Stroke colors */
  --color-stroke-normal: #222222;
  --color-stroke-muted: #808080;
  --color-stroke-hairline: #f6f6f6;
  --color-stroke-hairline: #22222233;

  /* TODO: Is this stroke color necessary and what should it be named? */
  --color-stroke-lowlight: #404040;

  --color-primary: #53b853;
  --color-primary-stroke: #141414;
  --color-primary-hover: #f0f0f0;
  --color-primary-select: #53b85360;
  --color-primary-disabled: #a0d6a0;
  --color-secondary: #a1a7a1;
  --color-secondary-stroke: #141414;

  --color-fill-body: #fafafa;
  --color-fill-element: #ffffff;
  --color-fill-element-lowlight: #f4f4f4;
  --color-fill-overlay: #ffffff80;
}
.theme-dark {
  /* Stroke colors */
  --color-stroke-normal: #dddddd;
  --color-stroke-muted: #606060;
  --color-stroke-hairline: #3b3b3b;
  --color-stroke-hairline: #dddddd33;

  /* TODO: Is this stroke color necessary and what should it be named? */
  --color-stroke-lowlight: #a4a4a4;

  --color-primary: #53b853;
  --color-primary-stroke: #141414;
  --color-primary-hover: #121212;
  --color-primary-select: #53b85360;
  --color-primary-disabled: #347f34;
  --color-secondary: #c1c7c1;
  --color-secondary-stroke: #141414;

  --color-fill-body: #181818;
  --color-fill-element: #101010;
  --color-fill-element-lowlight: #131313;
  --color-fill-overlay: #000000a0;
}
/* #endregion */

/* #region Theme Components */
.theme-button {
  display: inline-block;
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin: 0rem 1rem;
  border: none;
  border-radius: 1.25rem;
  background: linear-gradient(
    0.375turn,
    var(--color-fill-body) 0%,
    var(--color-fill-element) 100%
  );
}
