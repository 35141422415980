/* Defines some global constants accessible throughout all elements. */
html {
  /* Font constants */
  --font-small: 0.8em;
  --font-normal: 1em;
  --font-medium: 1.4em;
  --font-large: 1.8em;
  --font-title: 2.2em;

  /* Radius constants */
  --border-radius: 4px;

  /* Shadow constants */
  --shadow-size: 4px;
  --shadow: 0px 0px var(--shadow-size) 1px rgba(0, 0, 0, 0.1);
  --shadow-offset: 1px 1px var(--shadow-size) 1px rgba(0, 0, 0, 0.1);
}

/* Responsive size breakpoints. */
@media only screen and (max-width: 540px) {
}
@media only screen and (min-width: 540px) {
}
@media only screen and (min-width: 740px) {
}
@media only screen and (min-width: 900px) {
}
@media only screen and (min-width: 1080px) {
}
