.SampleBrowser{
    display: flex;
    flex-direction: column;
}

.sample-list{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.sample-expandable:hover{
    background: var(--color-primary-hover);
}

.sample-children{
    padding: 0.5em 0;
    margin-left: 1em;
    /* border-left: 0.05em solid black; */
    
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.sample-children-count{
    font-size: 0.8em;
    filter: invert(20%);
}

.sample-instance{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25em;
}